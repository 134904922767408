// Imports
import React from 'react';

export default class extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      items: [],
      lastQuery: 0,
    }
  }

  renderTableItem(update){
    return (
      <div key={update.settlementDate}>
        <p>{`${update.fails} FTD on ${update.settlementDate}`}</p>
      </div>
    )
  }

  render(){
    console.log(this.props)
    if (!this.props.data) return '';
    console.log(`rendering table with ${this.props.data.updates.length}`)

    const oneYearAgo = new Date().getTime() - 1000*60*60*24*365;
    const filtered = this.props.data.updates.filter(update => new Date(update.settlementDate).getTime() > oneYearAgo);
    const sorted = filtered.sort((a,b)=>new Date(b.settlementDate).getTime() - new Date(a.settlementDate).getTime())

    return (
      <div className="symbolTable">
        {sorted.map(this.renderTableItem)}
      </div>
    );
  }
}
