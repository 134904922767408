import React, { Component } from 'react';
import './App.css';
import SymbolSuggest from './components/symbolSuggest.js';
import SymbolChart from './components/symbolChart.js';
import SymbolTable from './components/symbolTable.js';

// Constants
const GET_SYMBOL_HISTORY = 'https://short.fail/api/v1/symbolHistory/';

// Helpers
const createHistoryUrl = query => GET_SYMBOL_HISTORY + String(query);

const getResults = async query => {
  const res = await fetch(createHistoryUrl(query));
  return res.json();
}


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      symbol: '',
      data: null,
      width: 700,
      height: 150,
    }
    this.onSymbolSelect = this.onSymbolSelect.bind(this)
  }

  async onSymbolSelect(item){
    console.log(item)
    const stockSymbol = item.props.item.symbol;
    console.log('got query for: '+stockSymbol);
    const results = await getResults(stockSymbol);
    this.setState({data:results});
  }

  render() {
    return (
      <div className="App">
        <div className="App-header">
          <SymbolSuggest
            onItemSelect={this.onSymbolSelect}
          />
          <SymbolChart
            symbol={this.state.symbol}
            data={this.state.data}
            width={this.state.width}
            height={this.state.height}
          />
          <SymbolTable
            data={this.state.data}
          />
        </div>

      </div>
    );
  }
}

export default App;
