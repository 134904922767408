import React, {Component} from 'react';
import * as d3 from "d3";


class SymbolChart extends Component {
  componentDidUpdate() {
    if (this.props.data) this.drawChart();
  }

  drawChart() {
    console.log(`drawing chart`)
    console.log(this.props.data);
    // const svg = d3.select("#symbolChart")
    // .append("svg")
    // .attr("viewBox", `0 0 415 700`)

    var margin = {top: 10, right: 30, bottom: 30, left: 60},
        width = 860 - margin.left - margin.right,
        height = 400 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3.select("#symbolChart")
      .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
      .append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");

    const parseSeconds = d3.timeParse("%s")
    // filter down to the past year of data
    const oneYearAgo = new Date().getTime() - 1000*60*60*24*365;
    const filtered = this.props.data.updates.filter(update => new Date(update.settlementDate).getTime() > oneYearAgo);
    const sorted = filtered.sort((a,b)=>new Date(a.settlementDate).getTime() - new Date(b.settlementDate).getTime())
    const data = sorted.map(update => ({value:update.fails,date:parseSeconds(new Date(update.settlementDate).getTime()/1000)}))
    // const {width,height} = this.props;
      // Add X axis --> it is a date format
      var x = d3.scaleTime()
        .domain(d3.extent(data, function(d) { return d.date; }))
        .range([ 0, width ]);
      svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));

      // Add Y axis
      var y = d3.scaleLinear()
        .domain([0, d3.max(data, function(d) { return +d.value; })])
        .range([ height, 0 ]);
      svg.append("g")
        .call(d3.axisLeft(y));

      // Add the line
      svg.append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-width", 1.5)
        .attr("d", d3.line()
          .x(function(d) { return x(d.date) })
          .y(function(d) { return y(d.value) })
          )
    }

  render(){
    if (!this.props.data) return <div className="symbolChart" id={'symbolChart'}></div>;
    return <div key={this.props.data.cusip} className="symbolChart" id={'symbolChart'}></div>
  }
}

export default SymbolChart;
