// Imports
import React from 'react';
import { MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";

// Constants
const QUERY_URL = 'https://short.fail/api/v1/symbolSearch/';

// Helpers
const createSearchUrl = query => QUERY_URL + String(query);

const getResults = async query => {
  const res = await fetch(createSearchUrl(query));
  return res.json();
}


export default class extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      items: [],
      lastQuery: 0,
    }
    this.symbolRenderer = this.symbolRenderer.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
  }
  symbolRenderer(item, {handleClick,modifiers,query}){
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        label={item.props.text}
        key={item.props.text}
        onClick={handleClick}
        text={item.props.description}
      />
    )
  }
  async onQueryChange(query,evt){
    if (!evt) return;
    const startTime = Date.now();
    const results = await getResults(query);
    const items = results.data
                    .map((item,idx)=> <MenuItem
                                          Key={item.symbol}
                                          text={item.symbol}
                                          item={item}
                                          description={item.description}
                                        />
                    ).slice(0,10);
    if (startTime > this.state.lastQuery) this.setState({items,lastQuery:startTime});
  }
  render(){
    return (<Suggest
                // createNewItemFromQuery={maybeCreateNewItemFromQuery}
                // createNewItemRenderer={maybeCreateNewItemRenderer}
                className='symbolSuggest'
                inputValueRenderer={x => x.props.text}
                itemRenderer={this.symbolRenderer}
                inputProps={{placeholder:'Search Symbol/Name'}}
                // itemsEqual={areFilmsEqual}
                // we may customize the default filmSelectProps.items by
                // adding newly created items to the list, so pass our own.
                items={this.state.items}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={this.props.onItemSelect}
                onQueryChange={this.onQueryChange}
                // popoverProps={{ minimal }}
            />);
  }
}
